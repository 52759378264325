import { gradients } from "./colors";

const buttons = {
  buttons: {
    default: {
      display: "block",
      cursor: "pointer",
      py: 2,
      px: 3,
      fontSize: [3, 2, 2],
      borderRadius: "4px",
      //textTransform: "uppercase",
      textDecoration: "none",
      fontWeight: "button",
    },
    primary: {
      variant: "buttons.default",
      bg: "primary",
      color: "white",

      //boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      iconButton: {
        display: "flex",
        color: "primary",

        p: 0,
        outline: {
          //variant: "buttons.default",
          display: "flex",
          color: "primary",
          borderRadius: "4px",
          borderColor: "primary",
          border: "solid",
          borderWidth: "1px",
          p: 0,
        },
      },
      outline: {
        variant: "buttons.default",
        a: { color: "white" },
        color: "secondary",
      
        border: "solid",
        borderWidth: "1px",
      },
      white: {
        variant: "buttons.default",
        bg: "white",
        color: "primary",
        boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      },
    },
    secondary: {
      variant: "buttons.default",
      bg: "secondary",
      color: "white",
      boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      white: {
        variant: "buttons.default",
        bg: "white",
        color: "secondary",

        boxShadow: "0px 4px 8px #998383, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      },
      menu: {
        variant: "buttons.default",
        bg: "secondary",
        color: "white",
        py: 1,
      },
    },
    disabled: {
      variant: "buttons.default",
      bg: "light",
      color: "textMuted",
    },
    textOnly: {
      cursor: "pointer",
      display: "block",
      bg: "transparent",
      m: 0,
      p: 0,
    },
    fixedSide: {
      variant: "buttons.default",
      fontSize: [1, null],
      borderBottomRightRadius: "0px",
      borderTopRightRadius: "0px",
      zIndex: "999",
      position: "fixed",
      top: "50%",
      right: "0px",
      color: "white",

      //transform: "rotate(-90deg)",
    },
    vimeo: {
      bg: "primary.gradient",

      fontSize: 5,
    },

    hero: {
      secondary: {
        variant: "buttons.default",
        width: "100%",
        py: 3,
        bg: "secondary",
        color: "white",
      },
    },
  },
};

export default buttons;
