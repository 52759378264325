import { capitalise } from "../helpers/string-helpers";
import Client from "./client";
import { CountryCode, getSdk, ProductFragmentFragment } from "./shopify";

export class Product {
  _countryCode: string;
  constructor(countryCode: string) {
    this._countryCode = countryCode;
  }
  async get(id: string) {
    const { GetProduct } = getSdk(Client);

    return (
      GetProduct({
        id,
        countryCode: CountryCode[capitalise(this._countryCode)],
      })
        //.then(resolver("node"))
        .then((result) => {
          if (!result) {
            return null;
          }

          return result.node as ProductFragmentFragment;
        })
    );
  }
  async getByHandle(handle: string) {
    const { GetProductByHandle } = getSdk(Client);

    return (
      GetProductByHandle({
        handle,
        countryCode: CountryCode[capitalise(this._countryCode)],
      })
        //.then(resolver("node"))
        .then((result) => {
          if (!result) {
            return null;
          }

          return result.productByHandle as ProductFragmentFragment;
        })
    );
  }
}
