import { Checkout } from "./checkout-sdk";
import { Product } from "./product-sdk";

import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";
import { CheckoutFragmentFragment } from "./shopify";

export type LineItem = DeepExtractTypeSkipArrays<
  CheckoutFragmentFragment,
  ["lineItems", "edges", "node"]
>;

export class RockitShopify {
  _countryCode: string;
  constructor(countryCode: string) {
    this._countryCode = countryCode;

    this.checkout = new Checkout(this._countryCode);
    this.product = new Product(this._countryCode);
  }
  checkout: Checkout;
  product: Product;
}
