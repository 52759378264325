export const sharedFormElementStyles = {
  //focus:outline-none focus:bg-white focus:border-gray-500"
  appearence: "none",
  display: "block",
  width: "100%",
  backgroundColor: "white",
  color: "text",
  borderColor: "primary",
  borderRadius: "4px",
  py: 2,
  px: 2,
  fontFamily: "body",

  "&:focus": {
    outline: "none",
    backgroundColor: "white",
    borderColor: "primary",
  },
};

const forms = {
  forms: {
    label: {
      fontFamily: "body",
      fontSize: ".75rem",
      fontWeight: "bold",
      //textTransform: "uppercase",
      letterSpacing: 0.5,
      color: "text",
      mb: 1,
      //block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2
    },
    labelDark: {
      variant: "forms.label",
      color: "white",
    },
    input: {
      ...sharedFormElementStyles,
      plain: {
        ...sharedFormElementStyles,
        borderWidth:0,
      
        borderRadius: "0px",
      },
    },
    inputDark: {
      ...sharedFormElementStyles,
      borderColor: "white",
    },
    select: {
      display: "block",
      width: "100%",
      backgroundColor: "white",
      color: "primary",
      borderColor: "primary",
      borderRadius: "8px",
      textTransform: "uppercase",
      p: 1,
      "&:focus": {
        outline: "none",
        backgroundColor: "white",
        borderColor: "primary",
      },
    },
    textarea: {
      ...sharedFormElementStyles,
      //borderColor: "supportingALight",
    },
    slider: {
      width: "300px",
      height: "5px",
      background: "#ddd",
      border: "none",
      borderRadius: "3px",
      "&::slider-thumb": {
        bg: "yellow",
      },
    },
    validationError: {
      //fontStyle: "italic",
      fontSize: "1em",
      color: "secondary",
      fontWeight: "bold",
    },

    radio: {
      imageRadio: {
        position: "absolute",
        left: "-9999px",
        "&:checked + div": {
          variant: "cards.radioIcon.selected",
        },
      },
    },
  },
};

export default forms;
