export const tables = {
  table: {
    tableLayout: "fixed",
    width: "100%",
   
  },
  thead: {
    bg: "primary",
    color: "white",
  },
  tfoot: {
    bg: "tableFootBG",
    color: "white",
  },


  th: {

    padding: 3,
  },
  td: {
    padding: 3,
  },

  /* zebra striping */

  "tbody tr:nth-of-type(odd)": {
    bg: "tableAltBGDark",
  },

  "tbody tr:nth-of-type(even) ": {
    bg: "tableAltBG",
  },
};
