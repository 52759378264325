import { capitalise } from "../helpers/string-helpers";
import Client from "./client";
import resolver from "./resolver";
import {
  CheckoutAttributesUpdateV2Input,
  CheckoutCreateInput,
  CheckoutFragmentFragment,
  CheckoutLineItemInput,
  CheckoutLineItemUpdateInput,
  CountryCode,
  getSdk,
} from "./shopify";

export class Checkout {
  _countryCode: string;
  constructor(countryCode: string) {
    this._countryCode = countryCode;
  }

  async get(id: string): Promise<CheckoutFragmentFragment | null> {
    const { GetCheckout } = getSdk(Client);

    return (
      GetCheckout({
        id,
        countryCode: CountryCode[capitalise(this._countryCode)],
      })
        //.then(resolver("node"))
        .then((checkout) => {
          if (!checkout) {
            return null;
          }

          return checkout.node as CheckoutFragmentFragment;
        })
    );
  }

  async create(
    input: CheckoutCreateInput
  ): Promise<CheckoutFragmentFragment | null> {
    const { CreateCheckout } = getSdk(Client);

    return (
      CreateCheckout({
        input,
        countryCode: CountryCode[capitalise(this._countryCode)],
      })
        //.then(resolver("node"))
        .then((result) => {
          if (!result) {
            return null;
          }

          return result.checkoutCreate.checkout;
        })
        .catch((err) => console.log("err", err))
    );
  }

  async updateAttributes(
    checkoutId: string,
    input: CheckoutAttributesUpdateV2Input
  ): Promise<CheckoutFragmentFragment> {
    const { checkoutAttributesUpdateV2 } = getSdk(Client);
    return checkoutAttributesUpdateV2({
      checkoutId,
      input,
      countryCode: CountryCode[capitalise(this._countryCode)],
    }).then((result) => {
      if (!result) {
        return null;
      }

      return result.checkoutAttributesUpdateV2.checkout;
    });
  }

  async addLineItems(
    checkoutId: string,
    lineItems: CheckoutLineItemInput | CheckoutLineItemInput[]
  ) {
    const { CheckoutLineItemsAdd } = getSdk(Client);
    return CheckoutLineItemsAdd({
      checkoutId,
      lineItems,
      countryCode: CountryCode[capitalise(this._countryCode)],
    }).then((result) => {
      if (!result) {
        return null;
      }

      return result.checkoutLineItemsAdd.checkout;
    });
  }

  async removeLineItems(checkoutId: string, lineItemIds: string | string[]) {
    const { CheckoutLineItemsRemove } = getSdk(Client);
    return CheckoutLineItemsRemove({
      checkoutId,
      lineItemIds,
      countryCode: CountryCode[capitalise(this._countryCode)],
    }).then((result) => {
      if (!result) {
        return null;
      }

      return result.checkoutLineItemsRemove.checkout;
    });
  }
  async updateLineItems(
    checkoutId: string,
    lineItems: CheckoutLineItemUpdateInput | CheckoutLineItemUpdateInput[]
  ) {
    const { CheckoutLineItemsUpdate } = getSdk(Client);
    return CheckoutLineItemsUpdate({
      checkoutId,
      lineItems,
      countryCode: CountryCode[capitalise(this._countryCode)],
    }).then((result) => {
      if (!result) {
        return null;
      }

      return result.checkoutLineItemsUpdate.checkout;
    });
  }
}
