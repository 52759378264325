/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { Fragment } from "react";
import { Script } from "gatsby";


export const PageScripts: React.FC = () => {
  return (
    <Fragment>
      <Script
        type="text/javascript"
        src="https://chat-application.com/embed/index.php?tracker_id=43461832"
        strategy="idle"
      
      />
      <Script
        id="Meta-Pixel"
        key="meta-pixel-config"
        strategy="post-hydrate"
        forward={["fbq"]}
        dangerouslySetInnerHTML={{
          __html: `! function(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function() {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
}(window, document, 'script',
    '/js/fb-events.js');
window.fbq('init', '166737620648457');
window.fbq('track', 'PageView');
`,
        }}
      />
    </Fragment>
  );
};

export default PageScripts;
