import ColorPallet from "./color-pallet.interface";

const pallet: ColorPallet = {
  transparent: "transparent",
  black: "#000",
  white: "#ffffff",
  whiteTransparent: [
    "#FFFFFF10",
    "#FFFFFF20",
    "#FFFFFF30",
    "#FFFFFF40",
    "#FFFFFF50",
    "#FFFFFF60",
    "#FFFFFF70",
    "#FFFFFF80",
    "#FFFFFF90",
    "#FFFFFF99",
  ],
  //Blue
  primary: {
    DEFAULT: "#1A75BA",
    "50": "#C3E0F7",
    "100": "#ADD5F3",
    "200": "#80BEED",
    "300": "#53A7E7",
    "400": "#2690E1",
    "500": "#1A75BA",
    "600": "#14598D",
    "700": "#0D3D61",
    "800": "#072134",
    "900": "#010407",
  },

  //Orange
  secondary: {
    DEFAULT: "#EF5A28",
    "50": "#FFFEFE",
    "100": "#FDECE6",
    "200": "#FAC7B6",
    "300": "#F6A387",
    "400": "#F37E57",
    "500": "#EF5A28",
    "600": "#D44110",
    "700": "#A5330C",
    "800": "#752409",
    "900": "#461505",
  },
  secondaryTransparent: [],

  //blue-gray
  neutrals: [
    "#F0F4F8",
    "#D9E2EC",
    "#BCCCDC",
    "#9FB3C8",
    "#829AB1",
    "#627D98",
    "#486581",
    "#334E68",
    "#243B53",
    "#102A43",
  ],

  //Green
  supportingA: {
    DEFAULT: "#8AC53E",
    "50": "#F5FAEE",
    "100": "#E9F4DB",
    "200": "#D1E8B4",
    "300": "#BADD8C",
    "400": "#A2D165",
    "500": "#8AC53E",
    "600": "#6FA030",
    "700": "#547924",
    "800": "#395218",
    "900": "#1D2A0D",
  },

  supportingB: [],
};

export default pallet;
