exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-paywhirl-tsx": () => import("./../../../src/pages/cart-paywhirl.tsx" /* webpackChunkName: "component---src-pages-cart-paywhirl-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-templates-builder-page-tsx": () => import("./../../../src/templates/builder/page.tsx" /* webpackChunkName: "component---src-templates-builder-page-tsx" */),
  "component---src-templates-category-page-template-tsx": () => import("./../../../src/templates/category-page-template.tsx" /* webpackChunkName: "component---src-templates-category-page-template-tsx" */),
  "component---src-templates-page-builder-template-tsx": () => import("./../../../src/templates/page-builder-template.tsx" /* webpackChunkName: "component---src-templates-page-builder-template-tsx" */),
  "component---src-templates-post-list-template-tsx": () => import("./../../../src/templates/post-list.template.tsx" /* webpackChunkName: "component---src-templates-post-list-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post.template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/product-template.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-shop-thanks-template-tsx": () => import("./../../../src/templates/shop-thanks-template.tsx" /* webpackChunkName: "component---src-templates-shop-thanks-template-tsx" */)
}

