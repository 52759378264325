import * as React from "react";
import "./src/styles/styles.css";
import "@fontsource/roboto/latin-100.css";
import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-700.css";
import "@fontsource/montserrat";
import "@fontsource/italiana";
import { StoreProvider } from "./src/context/store-context";
import { SiteProvider } from "./src/context/site-context";

export const wrapRootElement = ({ element }) => (
  <SiteProvider>
    <StoreProvider>{element}</StoreProvider>
  </SiteProvider>
);
//export const wrapPageElement = pageElement;

export const onRouteUpdate = ({ location }) => {
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) return null;
  if (process.env.NODE_ENV !== "production") {
    //return null;
  }

  //const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    //console.log("window", window, typeof window.fbq);
    if (typeof window.fbq === "function") {
      ///console.log("before track", window.fbq);
      window.fbq("track", "ViewContent");
      window.fbq('track', 'PageView');
    } else {
      //console.log("fbq not working");
    }
  }, 500);
};

export { wrapPageElement } from "./gatsby-shared";
