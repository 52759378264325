import { createContext, useEffect, useState } from "react";
import { getParamByISO } from "iso-country-currency";
import * as React from "react";
import { navigate } from "gatsby";
import {
  ApiError,
  ClientError,
  IpregistryClient,
  IpregistryOptions,
} from "@ipregistry/client";
interface Country {
  currency: {
    isoCode: string;
  };
  isoCode: string;
  language: string;
  locale: string;
  pathPrefix: string;
}

export interface Localisation {
  country: Country;
  defaultCountry: Country;

  isDefaultCountrySet: boolean;
}

interface SiteContext {
  localisation: Localisation;
  updateLocalisation: (localisation: Localisation) => void;
}

const defaultCountry: Country = {
  currency: {
    isoCode: "AUD", //TODO:  This may be depricated
  },
  isoCode: "AU",
  language: "en",
  locale: "en_AU",
  pathPrefix: "",
};

const defaultValues: SiteContext = {
  localisation: {
    country: defaultCountry,
    defaultCountry: defaultCountry,
    isDefaultCountrySet: true,
  },
  updateLocalisation: (localisation: Localisation) => {},
};
const LOCAL_STORAGE_KEY = "USER_LOCALE";

export const SiteContext = createContext<SiteContext>(defaultValues);

export const SiteProvider = ({ children }) => {
  const isBrowser = typeof window !== "undefined";

  //function to get the users localisation, from local storage/browser/ default AU

  //get the client locale from local storage.
  const clientLocalisation = localStorage.getItem(LOCAL_STORAGE_KEY);
  const isLocalePreStored = clientLocalisation !== null;

  //#region helper functions
  /**
   * a helper function to crreate a Localisation object
   *
   * @param {string} userCountry
   * @param {string} userLanguage
   * @returns
   */
  const createLocalisationObject = (
    userCountry: string,
    userLanguage: string
  ) => {
    const isDefualtCountry =
      userCountry === defaultValues.localisation.country.isoCode;

    const localisationObject: Localisation =
      !userCountry || isDefualtCountry
        ? defaultValues.localisation
        : {
            ...defaultValues.localisation,
            country: {
              currency: {
                isoCode: getParamByISO(userCountry.toUpperCase(), "currency"),
              },
              isoCode: userCountry,
              language: userLanguage,
              locale: `${userLanguage}_${userCountry}`,
              pathPrefix: `${userLanguage}_${userCountry}`,
            },
          };

    return localisationObject;
  };

  const lookupLocalisationFromPath = () => {
    if (isBrowser) {
      const pathName = window.location.pathname;
      const pathSegments = pathName.split("/");
      const countrySegment = pathSegments[1];

      const languageArray = countrySegment.split("_");

      //console.log("xx", pathName, pathSegments, countrySegment, languageArray);

      let userLanguage = languageArray[0];
      let userCountry = languageArray[1];

      const localisationObject = createLocalisationObject(
        userCountry,
        userLanguage
      );
      return localisationObject;
    }
  };

  /**
   * a method to get the locale info from the browser
   *
   * @returns a localisation object
   */
  const lookupLocalisationFromBrowser = () => {
    //console.log("lookupLocalisationFromBrowser");

    const language = navigator.language;
    const languageArray = language.split("-");

    let userLanguage = languageArray[0];
    let userCountry = languageArray[1];
    console.log(
      "userLang",
      userCountry,
      userLanguage,
      localisation.country.isoCode
    );
    if (localisation.country.isoCode !== userCountry) {
      const localisationObject = createLocalisationObject(
        userCountry,
        userLanguage
      );

      updateLocalisation(localisationObject);
    }

    // console.log(
    //   "userLang",
    //   language,
    //   userCountry,
    //   userLanguage,
    //   localisationObject
    // );
  };
  /**
   * gets the user locale based on IP.  THis actually runs an async external API call.  On promise completion it will set
   * the local store
   *
   */
  const lookupUserLocaleInfoFromIP = () => {

    
    const client = new IpregistryClient(
      process.env.GATSBY_IPREGISTRY_API || ""
    );
    client
      .originLookup(
        IpregistryOptions.filter("location.country.code,location.language.code")
      )
      .then((ipData) => {
        //I am going to compare the IP determined locale with the browser
        // if the browser country is different then I will set it to the IP based locale
        //otherwise I'll use the browser.  I will assume the people in frend speaking canada will
        //have their locales sorted, while half the fools in Australia have their browser locale set to US

      
        const userCountry = ipData.data.location.country.code;
        const userLanguage = ipData.data.location.language.code;
        if (localisation.country.isoCode !== userCountry) {
          const localisationObject = createLocalisationObject(
            userCountry || "AU",
            userLanguage || "en"
          );

          updateLocalisation(localisationObject);
        }
      });
  };

  //update localisation.
  const updateLocalisation = (localisation: Localisation) => {
    const isBrowser = typeof window !== "undefined";
    if (
      !["au", "us", "gb"].find(
        (l) => l === localisation.country.isoCode.toLocaleLowerCase()
      )
    ) {
      localisation.country = localisation.defaultCountry;
    }

    localisation.isDefaultCountrySet =
      localisation.defaultCountry.locale === localisation.country.locale;
    //set local storage

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localisation));
    setlocalisation(localisation);
  };

  const parseClientLocalisation = () => {
    const localisationObject = JSON.parse(clientLocalisation);
    if (localisationObject) {
      //quick hack to allow only the below counutry codes
      if (
        !["au", "us", "gb"].find(
          (l) => l === localisationObject.country.isoCode.toLocaleLowerCase()
        )
      ) {
        localisationObject.country = localisationObject.defaultCountry;
      }

      localisationObject.isDefaultCountrySet =
        localisationObject.defaultCountry.locale ===
        localisationObject.country.locale;
    }
    return localisationObject;
  };
  //#endregion

  //this is our all important localisation that is exposed by the context and used everywhere!
  //there is a coalescing of values here to ensure that it is never null
  const [localisation, setlocalisation] = useState<Localisation>(
    parseClientLocalisation() ||
      //lookupLocalisationFromBrowser() ||
      defaultValues.localisation
  );

  //#region  useEffects

  useEffect(() => {
    lookupUserLocaleInfoFromIP();
    //lookupLocalisationFromBrowser();
    if (!isLocalePreStored) {
      //lookupUserLocaleInfoFromIP();
      //lookupLocalisationFromBrowser();
    } else {
    }
  }, []);

  useEffect(() => {
    //we'll make sure the localisation is stored in local storage whenever it changes
    if (localisation) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localisation));
    }
  }, [localisation]);

  //#endregion

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        localisation,
        updateLocalisation,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
