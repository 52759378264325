import React from "react";
import PageScripts from "./src/templates/scripts";

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <PageScripts />
    </>
  );
};
