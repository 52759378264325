import pallet from "./color-pallets/site-colors";

const colors = {
  primaryLight: pallet.primary[400],
  primaryLightBG: pallet.primary[300],
  primary: pallet.primary.DEFAULT,
  primaryDark: pallet.primary[700],
  primaryTransparent: `${pallet.primary[0]}99`,
  modalBackdrop: `${pallet.neutrals[9]}90`,

  secondary: pallet.secondary.DEFAULT,
  supportingA: pallet.supportingA.DEFAULT,

  whiteTransparent: pallet.whiteTransparent[9],

  text: "#252525",
  textMuted: pallet.neutrals[5],
  textLight: pallet.neutrals[1],
  textHeading: pallet.primary.DEFAULT,
  textNav: pallet.primary["white"],

  light: pallet.neutrals[1],

  alert: "#FEEC34", //yellow

  background: "#F8FAFB",

  tableHeadBG: pallet.primary[700],
  tableFootBG: pallet.primary[600],
  tableAltBG: pallet.primary[50],
  tableAltBGDark: pallet.primary[100],

  inputBG: pallet.primary[100],

  twitter: "#1A91DA",
};

export default colors;

export const gradients = {
  primary: {
    backgroundImage: () => {
      return `linear-gradient(90deg, #004257 0%, #004D4B 79.17%);`;
    },
  },
};

export const colorPallet = { ...pallet };
