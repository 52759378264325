import { GraphQLClient } from "graphql-request";

// Get the URI for Apollo backend from .env file or use localhost
const API_URI = `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2022-01/graphql.json`;
const Client = new GraphQLClient(API_URI, {
  headers: {
    "X-Shopify-Storefront-Access-Token":process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
});

export default Client;
