export const Hero = {
  background: {
    position: "absolute",
    top: 0,
    height: "560px",
    width: "100%",
    zIndex: -1,
  },
  contentWrapper: {
    //mt:1,
    //top: [0, 4],
    //left: [0, 0],
    //left: 0,
    //right:0,
    variant: "layout.box",
    //position: "absolute",
    width: ["100%"],
    justifyContent: "center",
    alignItems: ["center", "flex-start"],
    display: "flex",
    color: "white",
    flexDirection: ["column"],
    pl: [2, 0],
  },
  h1: {
    width: "100%",
    display: "flex",
    flexDirection: ["column"],
    fontWeight: ["bold", "700"],
    fontSize: [4, 6],
    textTransform: "capitalize",
    alignItems: ["center", "flex-start"],
    span: { lineHeight: ["30px", "56px"] },
    pb: [1, 4],
    textAlign: ["center", "left"],
  },
  message: {
    width: ["100%"],
    fontWeight: ["400"],
    fontSize: [1, 3],
    textAlign: ["center", "left"],
    color: "white",
    pb: [4],
  },
};
