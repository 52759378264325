import * as React from "react";

import { useContext } from "react";
import { SiteContext } from "./site-context";

import { LineItem, RockitShopify } from "../../shopify/shopify.sdk";
import { CheckoutFragmentFragment, CountryCode } from "../../shopify/shopify";
import { capitalise } from "../../helpers/string-helpers";
import Client from "shopify-buy";

export const MEDICAL_DISCLAIMER_FIELD_NAME = "Acknowlede Medical Disclaimer";
export const SMS_OPT_IN_FIELD_NAME = "SMS Opt In";

const clientSDK = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
);

const localStorageKey = `shopify_checkout_id`;
type Checkout = CheckoutFragmentFragment & {
  lineItems: LineItem[];
};
type StoreContext = {
  cart: any[];
  isOpen: boolean;
  loading: boolean;
  didJustAddToCart: boolean;
  onOpen: () => void;
  onClose: () => void;
  addVariantToCart: (variantId: string, quantity: string) => Promise<any>;
  removeLineItem: (checkoutID: string, lineItemID: string) => void;
  updateLineItem: (
    checkoutID: string,
    lineItemID: string,
    quantity: string
  ) => void;
  setMedicalDisclaimer: (acknowledged: boolean) => void;
  setSMSOptIn: (acknowledged: boolean) => void;
  checkout: Checkout;
  shopifyClient: RockitShopify;
  setLoading: (boolean) => void;
  addDiscountCode: (discountCode: string) => Promise<any>;
};

const defaultValues: StoreContext = {
  cart: [],
  isOpen: false,
  loading: false,
  didJustAddToCart: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: (variantId: string, quantity: string) =>
    new Promise(() => {}),
  removeLineItem: (checkoutID: string, lineItemID: string) => {},
  updateLineItem: (
    checkoutID: string,
    lineItemID: string,
    quantity: string
  ) => {},
  setMedicalDisclaimer: (acknowledged: boolean) => {},
  setSMSOptIn: (acknowledged: boolean) => {},
  checkout: null,
  shopifyClient: null,
  setLoading: (boolean) => {},
  addDiscountCode: (discountCode: string) => {},
};

//Setup our Store context
export const StoreContext = React.createContext(defaultValues);

//Reference to our custom shopify Library.  Will be initialised in the useEffect for our localisation
let shopify: RockitShopify;
/**
 * simple var to determin if we are in the broser or not.  Needed for gasby build etc
 */
const isBrowser = typeof window !== `undefined`;

/**
 *
 *
 * @param {*} { children }
 * @returns
 */
export const StoreProvider = ({ children }) => {
  //#region React States

  const [checkout, setCheckout] = React.useState<Checkout>(
    defaultValues.checkout
  );
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const { localisation } = useContext(SiteContext);
  //#endregion

  const setCheckoutItem = (checkout: CheckoutFragmentFragment) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(mapCheckout(FixCartWebUrl(checkout)));
  };

  /**
   *  Used to do some manipulation on the checkout object to make it a bit more user friendly
   *
   * @param {CheckoutFragmentFragment} checkout The checkout object as returned from the Shopify API
   * @returns {Checkout} THe bastardised version of the Checkout
   */
  const mapCheckout = (checkout: CheckoutFragmentFragment): Checkout => {
    const checkoutNoLineItems = {
      ...checkout,
      lineItems: undefined,
    };
    checkoutNoLineItems.lineItems = checkout.lineItems.edges.map((e) => e.node);

    //Object.entries(checkout).filter(([key, _])=>key!== "lineItems")

    return {
      ...checkoutNoLineItems,
    };
  };

  //#region  useEffects

  React.useEffect(() => {
    //wait for the localisation to be determined
    if (!localisation) return;
    //We need to make sure we set the buyer Identity.  This is needed for SHopify to handle the cart correctly later on
    const countryCode = CountryCode[capitalise(localisation.country.isoCode)];

    //Create a new Shopify SDK Object (this is the cusom one) to make sure we always have the correct country set up.
    shopify = new RockitShopify(localisation.country.isoCode);

    //named function to manage the checkout.  Either retrieve an existing checkout or create a new one
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await shopify.checkout.get(
            existingCheckoutID
          );

          //we are going to make sure that the currency the existing checkout has is the SAME as the
          //current country.

          if (!existingCheckout?.completedAt) {
            if (
              existingCheckout?.currencyCode.toUpperCase() ===
              localisation.country.currency.isoCode.toUpperCase()
            ) {
              setCheckoutItem(FixCartWebUrl(existingCheckout));
              return;
            } else {
              //We need to create a new checkout (with any line items in the existing one)
              const exisitingLineItems = existingCheckout?.lineItems.edges.map(
                (lineItem) => ({
                  quantity: lineItem.node.quantity,
                  variantId: lineItem.node.variant.id,
                })
              );
              setLoading(true);
              shopify.checkout
                .create({
                  lineItems: exisitingLineItems,
                  buyerIdentity: {
                    countryCode: countryCode,
                  },
                })
                .then((newCheckout) =>
                  setCheckoutItem(FixCartWebUrl(newCheckout))
                )
                .finally(() => setLoading(false));

              return;
            }
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }
      const newCheckout = await shopify.checkout.create({
        buyerIdentity: {
          countryCode: countryCode,
        },
      });

      if (newCheckout) setCheckoutItem(FixCartWebUrl(newCheckout));
    };

    initializeCheckout();
  }, [localisation]);

  //#endregion
  /**
   * A private function to update Shopify checkout attributes
   *
   * @param {string} key //the key you want e.g Acknowlede Medical Disclaimer
   * @param {string} value //the value
   * @returns
   */
  const setCustomAtttribute = (key: string, value: string) => {
    if (!checkout?.id) return null;
    const checkoutID = checkout.id;
    shopify.checkout
      .updateAttributes(checkoutID, {
        customAttributes: [{ key: key, value: value }],
      })
      .then((checkout) => {
        setCheckout(mapCheckout(FixCartWebUrl(checkout)));
      });
  };

  //#region Context Methods
  /**
   * sett the medical disclaimer value
   *
   * @param {boolean} acknowledged
   */
  const setMedicalDisclaimer = (acknowledged: boolean) => {
    setCustomAtttribute(
      MEDICAL_DISCLAIMER_FIELD_NAME,
      acknowledged ? "YES" : "NO"
    );
  };

  //#region Context Methods
  /**
   * sett the medical disclaimer value
   *
   * @param {boolean} acknowledged
   */
  const setSMSOptIn = (acknowledged: boolean) => {
    setCustomAtttribute(SMS_OPT_IN_FIELD_NAME, acknowledged ? "YES" : "NO");
  };

  const addVariantToCart = (variantId: string, quantity: string) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    return shopify.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        console.log("xx", res);
        setCheckout(mapCheckout(FixCartWebUrl(res)));
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
        return res;
      });
  };

  const removeLineItem = (checkoutID: string, lineItemID: string) => {
    setLoading(true);

    return shopify.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(mapCheckout(FixCartWebUrl(res)));
        setLoading(false);
      });
  };

  const updateLineItem = (
    checkoutID: string,
    lineItemID: string,
    quantity: string
  ) => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return shopify.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(mapCheckout(FixCartWebUrl(res)));
        setLoading(false);
      });
  };

  const addDiscountCode = (discountCode: string): Promise<any> => {
    const checkoutID = checkout.id;

    //console.log("aaa", checkoutID, discountCode);
    setLoading(true);
    return clientSDK.checkout
      .addDiscount(checkoutID, discountCode)
      .then((response) => {
        console.log("add discount respnse", response);
        // Do something with the updated checkout
        setCheckout(response);
        setLoading(false);
       
        return response;
      });
  };

  //#endregion

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        setMedicalDisclaimer,
        setSMSOptIn,
        checkout,
        loading,
        didJustAddToCart,
        shopifyClient: shopify,
        setLoading,
        addDiscountCode,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

//helper function to fix incorrect cart URL domain.  Sometimes shopify does't use the correct URL argh
const FixCartWebUrl = (
  checkout: CheckoutFragmentFragment
): CheckoutFragmentFragment => {
  //console.log("checkout", checkout);

  //quick hack to use the correct URL

  const url = new URL(checkout?.webUrl);
  url.hostname = "shop.quitstopnow.com"; //TODO remove hard coding
  //console.log(url);

  const updatedCheckout: CheckoutFragmentFragment | null = {
    ...checkout,
    webUrl: url.href,
  };

  //console.log("updated checkout", updatedCheckout);
  return updatedCheckout;
};
